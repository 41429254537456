
  .view-modal, .popup{
    position: absolute;
    left: 50%;
  }
  
  button{
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 4px;
    border: 2px solid transparent;
    transition: background 0.1s linear, border-color 0.1s linear, color 0.1s linear;
  }

  .popup{
    background: #fff;
    padding: 25px;
    border-radius: 15px;
    top: -150%;
    max-width: 380px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 10px 15px rgba(0,0,0,0.3);
    transform: translate(-50%, -50%) scale(1.2);
    transition: top 0s 0.2s ease-in-out,
                opacity 0.2s 0s ease-in-out,
                transform 0.2s 0s ease-in-out;
  }
  
  .popup.show{
    top: 50%;
    opacity: 1;
    pointer-events: auto;
    transform:translate(-50%, -50%) scale(1);
    transition: top 0s 0s ease-in-out,
                opacity 0.2s 0s ease-in-out,
                transform 0.2s 0s ease-in-out;
  
  }

  .popup :is(header, .icons, .field){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .popup header{
    padding-bottom: 15px;
    border-bottom: 1px solid #ebedf9;
  }
  


  header .close, .icons a{
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
  }
  
  header .close{
    font-size: 17px;
    height: 33px;
    width: 33px;
    cursor: pointer;
  }
  
  header .close:hover{
    background: #ebedf9;
    color: black;
  }

  .close {
    opacity: 0.6;
  }

  .popup .content{
    margin: 20px 0;
  }
  .popup .icons{
    margin: 15px 0 20px 0;
    padding-left: 0px;
    padding: 0px 10px;
  }
  .content p{
    font-size: 16px;
  }
  .content .icons a{
    height: 50px;
    width: 50px;
    font-size: 20px;
    text-decoration: none;
    border: 1px solid transparent;
  }
  .icons a i{
    transition: transform 0.3s ease-in-out;
  }
  .icons a:nth-child(1){
    fill: #1877F2;
    border-color: #b7d4fb;
  }
  .icons a:nth-child(1):hover{
    background: #1877F2;
    fill: white;
  }
  .icons a:nth-child(2){
    fill: #0f1419;
    border-color: #0f1419;
  }
  .icons a:nth-child(2):hover{
    background: #0f1419;
    fill: white;
  }
  .icons a:nth-child(3){
    fill: #e1306c;
    border-color: #f5bccf;
  }
  .icons a:nth-child(3):hover{
    background: #e1306c;
    fill: white;
  }
  .icons a:nth-child(4){
    fill: #25D366;
    border-color: #bef4d2;
  }
  .icons a:nth-child(4):hover{
    background: #25D366;
    fill: white;
  }
  .icons a:nth-child(5){
    fill: #0088cc;
    border-color: #b3e6ff;
  }
  .icons a:nth-child(5):hover{
    background: #0088cc;
    fill: white;
  }
  .icons a:hover{
    color: #fff;
    border-color: transparent;
  }
  .icons a:hover i{
    transform: scale(1.2);
  }
  .content .field{
    margin: 12px 0 10px 0;
    height: 45px;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #e1e1e1;
  }

  .field > svg {
    margin-right: 10px;
    margin-left: 3px;
    color: black;
  }

  .field input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 12px;
  }

  .field button{
    color: #fff;
    padding: 5px 18px;
    background: rgb(240, 118, 29);
  }

  .field button:hover{
    background: rgba(240, 118, 29, .7);
  }
  