.txt-intelaf-labeled {
    position: relative;
  }
  .txt-intelaf-labeled label {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 8px;
    color: #aeaeae;
    white-space: nowrap;
    font-weight: 600;
    letter-spacing: 0.055em !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: uppercase;
  }
  .txt-intelaf-labeled input {
    width: 100%;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background-color: #f7f7f7;
    border-style: none;
    border-radius: 10px;
    font-size: 14px;
    color: #5e5e5e;
  }

  .txt-intelaf-labeled ::placeholder {
    color: #aeaeae;
  }

  .txt-intelaf-labeled input:focus-visible {
    background-color: rgba(240, 118, 29, 0.02) !important;
  }

  .txt-intelaf-labeled svg {
    color: #CACACC;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }