.container-sucursal {
    width: 100%;
    margin-bottom: 8px;
    border-radius: 10px;
    padding: 8px 8px;
    cursor: pointer;
}

.container-sucursal:hover {
    background-color: rgba(243, 243, 243, 1);
}

.container-sucursal > p {
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
    margin-left: 5px;
}

.container-sucursal > div {
    display: flex;
    align-items: center;
}

.container-sucursal > div > span {
    font-size: 17px;
    margin-right: 4px;
}

.container-sucursal > div > p {
    font-size: 11px;
    margin: 0px;
}