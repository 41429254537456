.switch-metodo-card {
    display: flex;
    min-height: 60px;
    background-color: rgba(243, 243, 243, 1);
    color: rgba(108, 108, 108, 1);
    border-radius: 10px;
    cursor: pointer;
}

.switch-metodo-card > .switch-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.switch-metodo-card > .switch-radio-activo {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgba(19, 180, 188, 1);
    background-color: rgba(19, 180, 188, 0.05);
    color: rgba(19, 180, 188, 1);
    width: 100%;
    border-radius: 10px;
}

.switch-metodo-card > .switch-radio-activo svg path{
    fill: rgba(19, 180, 188, 1);
}

.switch-metodo-card > .switch-radio >.switch-metodo-icon {
    font-size: 30px;
    margin-right: 5px;
    letter-spacing: -2px !important;
    font-weight: 300;
}

.switch-metodo-card > .switch-radio > p {
    font-size: 16px;
    margin: 0px;
    padding-left: 15px;
}


@media only screen and (max-width: 500px) {
    .switch-metodo-card > .switch-radio > p {
        font-size: 14px;
    }
    .switch-metodo-card > .switch-radio >.switch-metodo-icon {
        font-size: 35px;
    }
}

@media only screen and (max-width: 450px) {
    .switch-metodo-card > .switch-radio > p {
        font-size: 14px;
    }

    .switch-metodo-card > .switch-radio >.switch-metodo-icon {
        margin-right: 8px;
        margin-left:  8px;
    }
}