.buttonCollapse {
    height: 40px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.buttonCollapse:hover {
    opacity: 0.8;
}

.buttonCollapse > .text {
    font-size: 13px;
    letter-spacing: -0.65px !important;
}

.buttonCollapse > span {
    margin: 0px;
}

.buttonCollapse-spinner {
    max-width: 40px;
    max-height: 40px;
}

/*Agregar nuevos diseños*/
.buttonCollapse-light-grey {
    background-color: rgba(247, 247, 247, 1);
    color: rgba(0, 0, 0, 0.35);
}
