
.modal-metodo-container {
    max-width: 500px;
    margin: 4vh auto;
    background-color: white;
    padding: 10px 10px;
    border-radius: 20px;
    padding-bottom: 25px;
}

.modal-metodo-container > .card-metodo {
    padding: 0px 15px;
}

.modal-metodo-container > .card-metodo > p {
    margin: 0px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(94, 94, 94, 1);
    text-align: center;
    margin-bottom: 15px;
}

.modal-metodo-close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.modal-metodo-close:hover {
    color: rgba(94, 94, 94, 0.6);
}

.modal-metodo-scroll {
    height: 320px;
    overflow: hidden;
    flex: 1;
    flex-basis: 1e-9px;
    overflow-y: auto;
    scrollbar-width: thin;
    padding: 0px 5px;
}

@media only screen and (max-width: 500px) {
    .modal-metodo-container {
        max-width: 450px;
    }
}

@media only screen and (max-width: 450px) {
    .modal-metodo-container {
        max-width: 350px;
    }
}