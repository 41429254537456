.Metodo-de-entrega-precios {
    display: none;
}

@media only screen and (max-width: 1000px) {

    .Metodo-de-entrega-precios {
        display: block;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .Metodo-de-entrega-sucursales {
        display: none;
    }

} 

/*/Metodo de entrega*/
.metodo-de-entrega {
    background-color: #FFFFFF;
    max-width: 100%;
    height: 50px;
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.metodo-de-entrega > .sucursal-activa {
    min-width: 145px;
}

.metodo-de-entrega > .sucursal-activa > .name {
    font-size: 13px;
    font-weight: 500;
}

.metodo-de-entrega > .sucursal-activa > .direccion {
    font-size: 11px;
    font-weight: 300;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 150px;
}

.metodo-de-entrega > .sucursal-activa > p {
    margin: 0px;
}

.metodo-de-entrega > .cantidad > .cantidad-number {
    color: white;
    border-radius: 20px;
    width: max-content;
    height: 19px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin: 0px;
    padding: 0px 6px;
}

.metodo-de-entrega > .cantidad > .cantidad-number > p {
    margin: 0px;
    padding: 0px;
    text-align: center;
}