

.footer-seccion-titulo {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}


.footer-seccion-contacto{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
}

.footer-seccion-contacto-contactanos-grid{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footer-seccion-contacto-virtual-store{
    max-width: 120px;
    max-height: 40px;
    margin-right: 15px;
}

.footer-seccion-contacto-contactanos{
    display: inline-block;
    background-color: lightblue; /* Optional styling */
    padding: 5px; /* Optional styling */
    color: rgba(240, 118, 29, 1);
    background-color: rgba(240, 118, 29, 0.1);
    border-radius: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

@media screen and (min-width: 600px) {
    .footer-seccion-contacto{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 900px) {

    .footer-seccion-contacto{
        grid-template-columns: repeat(3, 1fr);
    }
}