.especificaciones {
  display: flex;
  justify-content: center;
  padding: 3rem;
  background-color: #fff;
  margin-top: 5rem;
  border-radius: 12px;
}

.tablas {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-auto-rows:max-content;
  width: 100%;
  min-width: 300px;
  font-size: 14px;
  background-color: #fff;
}

.tabla {
  border-radius: 11px 11px 11px 11px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.fila-primaria {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid rgba(34, 34, 34, 0.05);
  background-color: #f7f7f7;
  font-size: 16px;
  color: #222;
  font-weight: 700;
  padding: 10px 15px;
  align-content: center
}

.fila-encabezado {
  font-family: Nacelle, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 5px;
}

.fila {
  display: flex;
  justify-content: space-between;
}

.tabla .fila:last-child {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.columna-h-2,
.columna-h-4 {
  font-weight: 300;
  font-family: Nacelle, sans-serif;
  border: 1px solid rgba(34, 34, 34, 0.05);
  background-color: #fff;
  padding: 0.5rem;
  padding-left: 1.8rem;
  flex: 1;
}

.columna-h-1,
.columna-h-3 {
  font-weight: 500;
  font-family: Nacelle, sans-serif;
  border: 1px solid rgba(34, 34, 34, 0.05);
  background-color: #fff;
  padding: 0.5rem;
  padding-left: 1.8rem;
  flex: 1;
}