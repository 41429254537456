/*Sucursal*/
.container-sucursales {
    background-color: white;
    padding: 20px 24px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
}

.container-sucursales > div{
    width: 100%;
    max-width: 429px;
}

.container-sucursales > div > .sucursales {
    overflow: hidden;
    flex: 1;
    flex-basis: 19px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #dddddd #f5f5f5;
}

.container-sucursales > div > .sucursales::-webkit-scrollbar {
    width: 8px;
}

.container-sucursales > div > .sucursales::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
}

.container-sucursales > div > .sucursales::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 10px;
    animation: linear 3s;
}

.container-sucursales > div > .sucursales::-webkit-scrollbar-thumb:hover {
    background-color: darkgray;
 }


.container-sucursales > div > .disponibilidad-bodega {
    padding: 0px 1px;
    margin-bottom: 15px;
}

.container-sucursales > div > .disponibilidad-bodega > div {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.container-sucursales > div > .disponibilidad-bodega > div > p {
    font-size: 12px;
    margin: 0px;
    color: #8b8b8b;
}

.container-sucursales > div > p {
    font-size: 14px;
    font-weight: 500;
    color: rgba(139, 139, 139, 1);
    margin: 0px;
}

.container-sucursales > div > .sucursales > .card-tienda {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
}

.container-sucursales > div > .sucursales > .card-tienda:hover {
    background-color: rgba(243, 243, 243, 1);
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.container-sucursales > div > .sucursales > .card-tienda > .tienda > p {
    margin: 0px;
}

.container-sucursales > div > .sucursales > .card-tienda > .tienda > span > .tienda-title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(108, 108, 108, 1);
    margin: 0px;
}

.container-sucursales > div > .sucursales > .card-tienda > .tienda > span {
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: flex-end;
}

.container-sucursales > div > .sucursales > .card-tienda > .tienda > .tienda-direccion {
    font-size: 12px;
    font-weight: 300;
    color: rgba(130, 130, 130, 1);
}

.container-sucursales > div > .sucursales > .card-tienda > .tienda-cantidad {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(108, 108, 108, 0.5);
    margin-right: 10px;
}


@media only screen and (min-width: 1001px) {
    .container-sucursales > div {
        width: 650px;  
        max-width: 650px;
    }


}

@media only screen and (min-width: 1200px) {
    .container-sucursales {
        height: 550px; 
    }

    .container-sucursales > div {
        width: 250px;  
        max-width: 250px;
    }

    .container-sucursales > div > .sucursales {
        max-height: 310px;
    }
}

