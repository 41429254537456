.buttonWithIcon {
    height: 40px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    text-decoration: none;
}

.separacion-icon {
  margin: 5px;
}

.buttonWithIcon:hover {
    opacity: 0.7;
}

.buttonWithIcon > .text {
    font-size: 14px;
    font-weight: 500;
}

.buttonWithIcon > span {
    margin: 0px;
}


/*Agregar nuevos diseños*/
.buttonWithIcon-sub-grey {
    background-color: rgba(247, 247, 247, 1);
    border-style: solid;
    border-color:rgba(247, 247, 247, 1);
    border-width: 1px;
    color: gray;
}

.buttonWithIcon-light-grey {
  background-color: rgba(247, 247, 247, 1);
  border-style: solid;
  border-color:rgba(247, 247, 247, 1);
  border-width: 1px;
  color: rgba(0, 0, 0, 0.9);
}

.buttonWithIcon-whiteghost {
    background-color: white;
    border-style: solid;
    border-color:rgba(165, 165, 165, 0.75) ;
    border-width: 1px;
    color: rgba(165, 165, 165, 0.75);
}

.buttonWithIcon-Succces_g {
  background-color: white;
  border-style: solid;
  border-color:rgba(52, 189, 99, 1);
  border-width: 1px;
  color: rgba(52, 189, 99, 1);
}


.buttonWithIcon-Succces_g > span > svg > path {
  fill: rgba(52, 189, 99, 1);
}

.buttonWithIcon-Succces_g {
  background-color: white;
  border-style: solid;
  border-color:rgba(52, 189, 99, 1);
  border-width: 1px;
  color: rgba(52, 189, 99, 1);
}

.buttonWithIcon-Succces_g > span > svg > path {
  fill: rgba(52, 189, 99, 1);
}

.buttonWithIcon-Blue_btn {
  background-color: white;
  border-style: solid;
  border-color:rgba(38, 56, 128, 1);
  border-width: 1px;
  color: rgba(38, 56, 128, 1);
}

.buttonWithIcon-Blue_btn > span > svg > path {
  fill: rgba(19, 180, 188, 1);
}


.buttonWithIcon-WhiteRED {
  background-color: white;
  border-style: solid;
  border-color:red; 
  border-width: 1px;
  color: red;
}
 
.buttonWithIcon-WhiteRED > span > svg > path {
  fill: red;
}

.buttonWithIcon-whiteghost-light {
  background: #F8F8F8;
  border: 1.5px solid #DBDBDB;
}

.buttonWithIcon-whiteghost-light:hover span svg path {
  fill: white;
}

.buttonWithIcon-whiteghost-light-sin-border {
  background: rgb(233, 233, 233);
  text-decoration: none;
  color: rgb(5, 5, 5);
}

.buttonWithIcon-whiteghost-light-active svg path {
  fill: white;
}

.buttonWithIcon-whiteghost-light-active {
  background: #F0761D;
  color: white;
}

.buttonWithIcon-whiteghost-light:hover {
  background: #F0761D;
  color: white;
  opacity: 1;
}

.buttonWithIcon-orange {
  background-color: #f0761d;
  color: white;
}

.buttonWithIcon-orange-light {
  background-color: white;
  color: #f0761d;
  border: 1px solid #f0761d;
}


.buttonWithIcon-darkblue {
  background-color: #263880;
  color: white;
}

.buttonWithIcon-aqua {
  background-color: rgba(19, 180, 188, 0.15);
  color: rgba(19, 180, 188, 1);
}

.buttonWithIcon-darkAqua {
  background-color: rgba(19, 180, 188, 1);
  color:  white;
}

.buttonWithIcon-link {
  background-color: transparent;
  color: #AAAAAA;
}

.buttonWithIcon-link-favorito {
  background-color: transparent;
  color: #d4d4d4;
}

.buttonWithIcon-link-favorito-activo {
  color: #13B4BC;
  background-color: transparent;
}

.buttonWithIcon-link_active {
  background-color: transparent;
  color: #13B4BC;
}

