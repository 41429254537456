
.card-recomendado-producto {
    max-width: 230px;
    background-color: white;
    border-radius: 15px;
    cursor: pointer;
}

.card-imagen-producto {
    height: 190px;
    padding: 34px;
}

.card-imagen-producto > .imagen {
    width: 125px;
    height: 125px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    
}

.card-imagen-producto > .imagen > img {
    object-fit: contain;   
    width: 100%;
    height: 100%;
}

.card-detalle-producto {
    height:  100px;
    padding: 15px 20px;
}

.card-linea {
    margin-top: 0px; 
    margin-bottom: 0px;
}

.card-detalle-producto > .card-nombre {
    font-size: 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}
 
.card-detalle-producto > .card-codigo {
    font-size: 10px;
    font-weight: 400;
    color: rgba(165, 165, 165, 1);
}