
.footer-newsletter {
    margin-top: 20px;
    margin-bottom: 20px;
}

footer .footer-newsletter .grupo-footer .newsletter {
    background-color: white;
    width: 1fr;
    padding: 25px 35px;
    display: grid;
    grid-template-columns: 3fr 2fr 2fr;
    border-radius: 25px;
}

footer .footer-newsletter .grupo-footer .newsletter .newsletter-suscribirme {
    padding-right: 20px;
}

footer .footer-newsletter .grupo-footer .newsletter .newsletter-suscribirme .newsletter-suscribirme-input {
    margin-top: 15px;
    display: grid;
    border-radius: 15px;
    justify-content: space-between;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
    align-items: center;
}

footer .footer-newsletter .grupo-footer .newsletter .newsletter-cliente {

    border-left: 1px solid rgba(234, 234, 324, 0.65);
    border-right: 1px solid rgba(234, 234, 324, 0.65);
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}

footer .footer-newsletter .grupo-footer .newsletter .newsletter-cliente .newsletter-cliente-img {
    max-height: 50px;
}

footer .footer-newsletter .grupo-footer .newsletter .newsletter-whatsapp {
    display: flex;
    column-gap: 20px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
}

footer .footer-newsletter .grupo-footer .newsletter .newsletter-whatsapp .newsletter-whatsapp-img {
    max-height: 50px;
}

.hide-text {
    font-size: 0;
    color: transparent;
}

.subtitulo-newsletter {
    font-weight: 700;
    font-size: 14px;
    color: rgba(119, 119, 119, 1) !important;
}

.titulo-newsletter {
    font-size: 18px;
    color: rgba(34, 34, 34, 1) !important;
    margin-bottom: 4px;
}

.span-clickeable:hover {
    cursor: pointer;
    color: rgba(240, 118, 29, 1);
}

.interlineado-parrafo-newsletter {
    line-height: 1.2;
}

@media only screen and (max-width: 994px) {
    footer .footer-newsletter .grupo-footer .newsletter {
        background-color: white;
        width: 1fr;
        padding: 15px 25px;
        display: grid;
        row-gap: 25px;
        grid-template-columns: 1fr;
        border-radius: 25px;
    }

    footer .footer-newsletter .grupo-footer .newsletter .newsletter-cliente {

        border-left: 0px solid rgba(234, 234, 324, 0.65);
        border-right: 0px solid rgba(234, 234, 324, 0.65);

        border-top: 1px solid rgba(234, 234, 324, 0.65);
        border-bottom: 1px solid rgba(234, 234, 324, 0.65);
        display: flex;
        column-gap: 20px;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 20px;

    }

    footer .footer-newsletter .grupo-footer .newsletter .newsletter-suscribirme {
        padding-right: 0px;
    }
}