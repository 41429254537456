.container-reclutamiento-page  p {
    font-size: 16px;
}

.container-reclutamiento-page  img {
    max-width: 100%;
    margin-top: 25px;
}

.container-reclutamiento-page h1 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 5rem;
}

.container-reclutamiento-page > .subtitulo-formulario-reclutamiento > p {
    margin-top: -1rem;
}

.container-reclutamiento-page .formulario-reclutamiento {
    background-color: white;
    width: 1fr;
    padding: 3rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    border-radius: 25px;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-1 {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-2 {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr;
    gap: 2rem;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-3 {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 2fr 1.5fr;
    gap: 2rem;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-21 {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-4 {
    display: grid;
    align-items: center;
    grid-template-columns: 1.5fr 1.8fr 1fr 2fr;
    gap: 1rem;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-5 {
    display: grid;
    align-items: center;
    grid-template-columns: 0.75fr 1.2fr 1fr 1.69fr 0.75fr;
    gap: 1rem;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-6 {
    display: grid;
    align-items: center;
    grid-template-columns: 0.75fr 1.2fr 1fr 0.5fr 0.5fr 0.75fr;
    gap: 1rem;
    margin: 1rem;
}

.container-reclutamiento-page .formulario-reclutamiento .tabla-container-experieces {
    text-align: center;
    align-items: center;
    margin-top: 1rem;
}

.container-reclutamiento-page .reclutamiento-send {
    width: 30%;
    margin-top: 3rem;
    margin-bottom: 5rem;
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: center;
}

.button-add-experience {
    height: 3rem;
    margin-left: 3rem;
}

@media only screen and (max-width: 580px) {
    .container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-2,
    .container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-3,
    .container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-4,
    .container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-5,
    .container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-6,
    .container-reclutamiento-page .formulario-reclutamiento .formulario-fila-col-21 {
        align-items: center;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}