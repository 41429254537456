.btn-intelaf {
  position: relative;
  border-radius: 15px;
  line-height: 17px;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  border-style: none
}

.btn-intelaf:hover {
  opacity: 0.8;
}

.btn-intelaf-whiteghost {
  background-color: white;
  border-style: solid;
  border-color:rgba(165, 165, 165, 0.75) ;
  border-width: 1px;
  color: rgba(165, 165, 165, 0.75);
}

.btn-intelaf-orange {
  background-color: #f0761d;
  color: white;
}

.btn-intelaf-darkblue {
  background-color: #263880;
  color: white;
}

.btn-intelaf-aqua {
  background-color: rgba(19, 180, 188, 1);
  color: white;
}

.btn-intelaf-red {
  background-color: rgba(255, 59, 48, 1);
  color: white;
}

.btn-text {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.035em;
  transition: all 0.2s;
}

.btn-loading .btn-text {
  visibility: hidden;
  opacity: 0;
}

.btn-loading-claro .btn-text {
  visibility: hidden;
  opacity: 0;
}

.btn-loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  pointer-events: none;
}

.btn-loading-claro::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: rgba(165, 165, 165, 0.75);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  pointer-events: none;
}

.btn-intelaf::after {
  opacity: 0.8;
}


@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}