.carrusel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.thumbnail-image{
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    cursor: pointer;
    border-radius: 18px;
}

.carrusel-container .selected-image-container {
    position: relative;
    margin-bottom: 1rem;
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carrusel-container .selected-image-container .selected-image{
    max-width: 32rem;
    height: 32rem;
    border-radius: 1rem;
    cursor: zoom-in;
    object-fit: contain;
}

.carrusel-container .selected-image-container .prev-image-button{
    width: 30px;
    height: 30px;
    position: absolute;
    left: 5px;
    top: 50%;
    cursor: pointer;
    background-color: #f6f7f9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.carrusel-container .selected-image-container .next-image-button{
    width: 30px;
    height: 30px;
    position: absolute;
    right: 5px;
    top: 50%;
    cursor: pointer;
    background-color: #f6f7f9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.carrusel-container .thumbnails-container {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
}

.carrusel-container .thumbnails-container .thumbnail-image{
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    cursor: pointer;
    border-radius: 18px;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-container .carrusel-modal-container {
    width: 60%;
    height: 90%;
    position: relative;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-container .carrusel-modal-container .prev-modal-button {
    position: absolute;
    left: 5vw;
    top: 50%;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-container .carrusel-modal-container .zoom-modal-button {
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 10vh;
}

.modal-container .carrusel-modal-container .zoom-modal-button .selected-modal-image {
    object-fit: contain !important;
    max-width: 50vw !important;
    height: 59vh !important;
}

.modal-container .carrusel-modal-container .modal-divider {
    width: 100%;
    height: 2px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    background-color: #f3f3f3;
}

.modal-container .carrusel-modal-container .thumbnail-modal {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2vw;
    margin-bottom: 2vw;
}

.modal-container .carrusel-modal-container .next-modal-button {
    position: absolute;
    right: 5vw;
    top: 50%;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-container .carrusel-modal-container .close-modal-button {
    position: absolute;
    top: 0;
    right: 2vw;
    background-color: transparent;
    color: #CACACC;
    font-size: 50px;
    cursor: pointer;
    z-index: 9999;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.no-select {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}  

.modal-overlay.fade-in {
    animation: fadeInModal 0.4s forwards;
}

.modal-overlay.fade-out {
    animation: fadeOutModal 0.4s forwards;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

.fade-out {
    opacity: 1;
    animation: fadeOut 0.3s forwards;
}

@keyframes fadeInModal {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutModal {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.7);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media only screen and (min-width: 2200px) {
    .modal-container .carrusel-modal-container .zoom-modal-button {
        margin-top: 2vw;
    }
}

@media only screen and (max-width: 1800px) {
    .modal-container .carrusel-modal-container {
        width: 80%;
        height: 90%;
    }
}

@media only screen and (max-width: 1024px) {
    .modal-container .carrusel-modal-container {
        width: 80%;
        height: 80%;
    }
}

@media only screen and (max-width: 800px) {
    .modal-container .carrusel-modal-container {
        width: 80%;
        height: 65%;
    }
}

@media only screen and (max-width: 768px) {
    .modal-container .carrusel-modal-container {
        width: 80%;
        height: 55%;
    }

}

@media only screen and (max-width: 330px) {
    .carrusel-container .selected-image-container .selected-image{
        width: 20rem;
        height: 20rem;
    }
}

@media only screen and (max-width: 530px) {
    .carrusel-container .selected-image-container .selected-image{
        width: 26rem;
        height: 26rem;
    }
    .carrusel-container .thumbnails-container .thumbnail-image,
    .modal-container .carrusel-modal-container .thumbnail-modal .thumbnail-image {
        width: 12vw;
        height: 12vw;
    }
}

@media only screen and (max-width: 375px) {
    .modal-container .carrusel-modal-container .zoom-modal-button .selected-modal-image {
        width: 60vw !important;
        height: 60vw !important;
        object-fit: cover;
        margin-bottom: 20vw;
    }
    .carrusel-container .thumbnails-container .thumbnail-image,
    .modal-container .carrusel-modal-container .thumbnail-modal .thumbnail-image {
        width: 18vw;
        height: 18vw;
    }
}