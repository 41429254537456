html,
body,
iframe {
    height: 100%;
}

* {
    font-family: Inter,sans-serif !important;
    letter-spacing: -.17px !important;
    scrollbar-width: thin;
    scrollbar-color: #dddddd #f5f5f5;
}

body {
    background-color: #f9f9f9;
}

.btn-alert-swal-orange {
    font-size: 13px;
    font-weight: 400;
    color: white;
    border-radius: 5px;
    padding: 7px;
    background-color: rgba(240, 118, 29, 1);
    border: none;
}

.btn-alert-swal-cancel {
    font-size: 13px;
    font-weight: 400;
    color: white;
    margin-left: 12px;
    border-radius: 5px;
    padding: 7px;
    background-color: rgba(255, 58, 48, 0.8);
    border: none;
}

.btn-alert-swal-deny {
    font-size: 13px;
    font-weight: 400;
    color: white;
    margin-left: 12px;
    border-radius: 5px;
    padding: 7px;
    background-color: #13b4bc;
    border: none;
}

.swal-title-style {
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
    font-size: 22px !important;
}

.swal-popup-style {
    border-radius: 10px !important;
}

.swal2-close {
    margin-top: 8px !important;
    margin-right: 8px !important;
    font-size: 30px !important;
}

.swal2-html-container {
    font-size: 13px !important;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5) !important;
    z-index: 9999px !important;
}

.swal2-container {
    z-index: 9999 !important;
}

@media (min-width: 1200px) {
    .container {
        width: 1222px !important;
    }
}

@media screen and (min-width: 992px) {
    .container-banner-home .swiffy-slider {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
}

*::-webkit-scrollbar {
    width: 8px; /* Adjust width */
}

*::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray track */
}

*::-webkit-scrollbar-thumb {
    background: #c7c7c7; /* Darker gray thumb */
    border-radius: 5px; /* Rounded corners */
}


:root {
    --swiper-navigation-size: 30px !important;
}