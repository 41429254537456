/* Estilos para los párrafos y enlaces del footer */
footer p,
footer a {
    font-weight: 400;
    font-size: 12.5px;
    color: rgba(61, 61, 61, 0.8);
    letter-spacing: inherit;
    line-height: 12px;
}

footer b {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #707070;
    letter-spacing: -0.03em;
}

footer a:hover {
    color: rgba(240, 118, 29, 1);
    cursor: pointer;
}

footer .MuiListItem-root:hover {
    background-color: rgba(160, 160, 160, 0.2);
}